// @generated by protoc-gen-es v2.2.2 with parameter "target=ts"
// @generated from file types/value.proto (package types, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file types/value.proto.
 */
export const file_types_value: GenFile = /*@__PURE__*/
  fileDesc("ChF0eXBlcy92YWx1ZS5wcm90bxIFdHlwZXMiGgoJQm9vbFZhbHVlEg0KBXZhbHVlGAEgASgIIhwKC1N0cmluZ1ZhbHVlEg0KBXZhbHVlGAEgASgJIhsKCkJ5dGVzVmFsdWUSDQoFdmFsdWUYASABKAwiHAoKU3RyaW5nTGlzdBIOCgZ2YWx1ZXMYASADKAkiGwoKSW50NjRWYWx1ZRINCgV2YWx1ZRgBIAEoAyIbCglJbnQ2NExpc3QSDgoGdmFsdWVzGAEgAygDIgcKBUVtcHR5Qi9aLWdpdGh1Yi5jb20vZ2FyZXRoZ2VvcmdlL2JhY2tyZXN0L2dlbi9nby90eXBlc2IGcHJvdG8z");

/**
 * @generated from message types.BoolValue
 */
export type BoolValue = Message<"types.BoolValue"> & {
  /**
   * @generated from field: bool value = 1;
   */
  value: boolean;
};

/**
 * Describes the message types.BoolValue.
 * Use `create(BoolValueSchema)` to create a new message.
 */
export const BoolValueSchema: GenMessage<BoolValue> = /*@__PURE__*/
  messageDesc(file_types_value, 0);

/**
 * @generated from message types.StringValue
 */
export type StringValue = Message<"types.StringValue"> & {
  /**
   * @generated from field: string value = 1;
   */
  value: string;
};

/**
 * Describes the message types.StringValue.
 * Use `create(StringValueSchema)` to create a new message.
 */
export const StringValueSchema: GenMessage<StringValue> = /*@__PURE__*/
  messageDesc(file_types_value, 1);

/**
 * @generated from message types.BytesValue
 */
export type BytesValue = Message<"types.BytesValue"> & {
  /**
   * @generated from field: bytes value = 1;
   */
  value: Uint8Array;
};

/**
 * Describes the message types.BytesValue.
 * Use `create(BytesValueSchema)` to create a new message.
 */
export const BytesValueSchema: GenMessage<BytesValue> = /*@__PURE__*/
  messageDesc(file_types_value, 2);

/**
 * @generated from message types.StringList
 */
export type StringList = Message<"types.StringList"> & {
  /**
   * @generated from field: repeated string values = 1;
   */
  values: string[];
};

/**
 * Describes the message types.StringList.
 * Use `create(StringListSchema)` to create a new message.
 */
export const StringListSchema: GenMessage<StringList> = /*@__PURE__*/
  messageDesc(file_types_value, 3);

/**
 * @generated from message types.Int64Value
 */
export type Int64Value = Message<"types.Int64Value"> & {
  /**
   * @generated from field: int64 value = 1;
   */
  value: bigint;
};

/**
 * Describes the message types.Int64Value.
 * Use `create(Int64ValueSchema)` to create a new message.
 */
export const Int64ValueSchema: GenMessage<Int64Value> = /*@__PURE__*/
  messageDesc(file_types_value, 4);

/**
 * @generated from message types.Int64List
 */
export type Int64List = Message<"types.Int64List"> & {
  /**
   * @generated from field: repeated int64 values = 1;
   */
  values: bigint[];
};

/**
 * Describes the message types.Int64List.
 * Use `create(Int64ListSchema)` to create a new message.
 */
export const Int64ListSchema: GenMessage<Int64List> = /*@__PURE__*/
  messageDesc(file_types_value, 5);

/**
 * @generated from message types.Empty
 */
export type Empty = Message<"types.Empty"> & {
};

/**
 * Describes the message types.Empty.
 * Use `create(EmptySchema)` to create a new message.
 */
export const EmptySchema: GenMessage<Empty> = /*@__PURE__*/
  messageDesc(file_types_value, 6);

